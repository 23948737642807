import React, {useCallback, useEffect, useRef, useState} from "react"
import {AiAssistantChatMessage, AiAssistantChatSession, aiChatApi, SenderType} from "@services/aiChatApi"
import {ThreeDotLoader} from "../common/ThreeDotLoader"
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr"
import {useTenant} from "@services/tenants";
import Markdown, {Components} from "react-markdown";
import remarkGfm from "remark-gfm";

export function ChatView() {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState<AiAssistantChatMessage[]>([]);
    const [session, setSession] = useState<AiAssistantChatSession | null>(null);
    const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
    const chatBodyRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [inputText, setInputText] = useState("");
    const [showCancel, setShowCancel] = useState(false);
    const tenant = useTenant()

    const loadOrStartNewSession = useCallback(async (isNew: boolean = false) => {
        try {
            const sessionResponse = isNew ? await aiChatApi.startNewSession() : await aiChatApi.getSession();

            setSession(sessionResponse);
            setMessages(sessionResponse.messages);

            const connection = new HubConnectionBuilder()
                .withUrl(`/${tenant}/hubs/aiChatHub`)
                .withAutomaticReconnect()
                .configureLogging(LogLevel.Trace)
                .build();

            connection.on("SendMessages", (messages: AiAssistantChatMessage[], isFinal: boolean) => {
                console.log('Received messages:', messages);
                setMessages(prev => mergeMessages(prev, messages));
                setIsGeneratingResponse(!isFinal);
            });

            await connection.start()

        } catch (error) {
            console.error('Failed to load chat session:', error);
        }
    }, [tenant]);

    useEffect(() => {
        if (isOpen && !session) {
            loadOrStartNewSession().catch(error => console.error('Failed to load chat session:', error));
        }
    }, [isOpen, session, loadOrStartNewSession]);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (isOpen && !isGeneratingResponse && !inputText) {
            textareaRef.current?.focus();
        }
    }, [isOpen, isGeneratingResponse, messages, inputText]);

    useEffect(() => {
        if (!inputText && textareaRef.current) {
            textareaRef.current.style.height = "auto";
        }
    }, [inputText])

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        
        if (isGeneratingResponse) {
            timerId = setTimeout(() => {
                setShowCancel(true);
            }, 10000);
        } else {
            setShowCancel(false)
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [isGeneratingResponse]);

    const handleSendMessage = async () => {
        if (!inputText.trim()) return;

        const userMessage = {
            // random client id to identify the message when received from the server (with server-generated id)
            clientId: Math.random().toString(36).substring(2),
            message: inputText,
            sender: SenderType.User,
            timestamp: new Date().toISOString(),
        } as AiAssistantChatMessage;

        setMessages(prev => [...prev, userMessage]);
        setInputText("");
        setIsGeneratingResponse(true);

        try {
            const newMessages = await aiChatApi.sendMessage(userMessage.message, userMessage.clientId);
            newMessages.forEach(x => console.log(x.message));
            setMessages(prev => mergeMessages(prev, newMessages));
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setIsGeneratingResponse(false);
        }
    };

    function mergeMessages(messages: AiAssistantChatMessage[], newMessages: AiAssistantChatMessage[]) {
        // Replace messages with empty serverId and matching clientId
        let hasChanges = false;
        const updatedMessages = messages.map((msg) => {
            if (!msg.id && msg.clientId) {
                const replacement = newMessages.find(
                    (newMsg) => newMsg.clientId === msg.clientId
                );
                if (replacement) {
                    hasChanges = true;
                    return replacement;
                }
            }
            return msg;
        });

        // Create a set of existing message IDs (excluding undefined)
        const existingIds = new Set<string>(updatedMessages.map((msg) => msg.id).filter((id): id is string => id !== undefined));

        // Append only newMessages that don't already exist by serverId
        newMessages.forEach((newMsg) => {
            if (newMsg.id && !existingIds.has(newMsg.id)) {
                updatedMessages.push(newMsg);
                hasChanges = true;
            }
        });

        // If no changes were made, return the original array
        if (!hasChanges) {
            return messages;
        }

        // Sort by timestamp only if changes occurred
        return updatedMessages.sort(
            (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
        );
    }

    const handleCancelGeneration = async () => {
        try {
            await aiChatApi.cancelResponseGeneration();
        } catch (error) {
            console.error('Failed to cancel response generation:', error);
        }
    };

    // const chatFeedMessages = messages.map(msg => new Message({
    //     id: msg.sender === SenderType.User ? 0 : 1,
    //     message: (msg.sender === SenderType.Debug ? "[DEBUG]\n" : "") + msg.message
    // }));

    const components: Components = {
        table: ({ node, ...props }) => (
            <table className="border-collapse border border-gray-400 w-full" {...props} />
        ),
        th: ({ node, ...props }) => (
            <th className="border border-gray-400 bg-gray-200 p-2 text-left" {...props} />
        ),
        td: ({ node, ...props }) => (
            <td className="border border-gray-400 p-2" {...props} />
        ),
    };

    return (
        <div className="fixed bottom-4 right-4 z-50">
            {isOpen && (
                <div className="flex flex-col w-[26rem] h-[32rem] bg-white shadow-lg rounded-lg border border-gray-300">
                    <div className="flex items-center justify-between bg-gray-500 text-white px-4 py-2 rounded-t-lg">
                        <span className="font-medium">AI ассистент</span>
                        <div className="flex gap-4 items-center">
                            {!isGeneratingResponse && <button
                                onClick={() => loadOrStartNewSession(true)}
                                className="text-white text-xs"
                                aria-label="Новый чат"
                            >
                                Новый чат
                            </button>}
                            <button
                                onClick={() => setIsOpen(false)}
                                className="text-white font-bold text-lg"
                                aria-label="Закрыть чат"
                                style={{marginTop:-2}}
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                    <div className="flex-1 overflow-y-auto p-4 bg-gray-50" ref={chatBodyRef}>
                        {messages.map(msg => <div key={msg.id ?? msg.clientId}>
                            <div className={`flex ${msg.sender === SenderType.User ? "justify-end" : "justify-start"} mb-2`}>
                                <div className={`whitespace-pre-wrap p-2 rounded-lg ${msg.sender === SenderType.User 
                                        ? "bg-blue-500 text-white" 
                                        : msg.sender === SenderType.Debug 
                                            ? "bg-gray-300 text-gray-500 text-xs" 
                                            : "bg-gray-300 text-black"}`}>
                                    {msg.sender === SenderType.Debug && <span className="text-xs">[DEBUG]</span>}
                                    <div>
                                        {msg.sender === SenderType.Assistant ? <Markdown remarkPlugins={[remarkGfm]} components={components}>{msg.message}</Markdown> : msg.message}
                                    </div>
                                </div>
                            </div>
                        </div>)}
                        <div className="flex items-center">
                            {isGeneratingResponse && (
                                <div className="flex items-center leading-none ">
                                    <ThreeDotLoader style={{color: '#11113399'}} />
                                    {showCancel && (
                                        <button
                                            onClick={handleCancelGeneration}
                                            className="ml-4 text-sm text-red-400 hover:text-red-600"
                                        >
                                            Отменить
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center border-t border-gray-300 p-2">
                        <textarea
                            ref={textareaRef}
                            value={inputText}
                            onChange={e => setInputText(e.target.value)}
                            onKeyDown={e => {
                                if (e.key === "Enter" && !e.shiftKey && !isGeneratingResponse) {
                                    handleSendMessage().catch(error => console.error('Failed to send message:', error));
                                    e.preventDefault();
                                }
                            }}
                            onInput={e => {
                                const target = e.target as HTMLTextAreaElement;
                                target.style.height = "auto";
                                target.style.height = `${Math.min(target.scrollHeight + 2, 100)}px`;
                            }}
                            disabled={isGeneratingResponse}
                            placeholder={isGeneratingResponse ? "Пожалуйста, подождите..." : ""}
                            rows={1}
                            className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-hidden"
                        />
                    </div>
                </div>
            )}
            {!isOpen &&
                <button
                    onClick={() => setIsOpen(true)}
                    className="w-12 h-12 bg-blue-500 text-white rounded-full shadow-lg flex items-center justify-center text-xl hover:bg-blue-600 focus:ring-2 focus:ring-blue-500"
                    aria-label="Открыть окно чата"
                >
                    AI
                </button>
            }
        </div>
    );
}
